<template>
  <div class="favorite-carriers">
    <div class="favorite-carriers__back" @click="changeView('Settings')">
      <i class="ion ion-chevron-back-outline"></i>
      <span>{{ $t("settings.back") }}</span>
    </div>
    <div class="favorite-carriers-container">
      <div class="carrier-row" v-for="(carrier, index) in carriersImages" :key="index">
        <div class="carrier-row__image"><img :src="carrier.url" :alt="carrier.name" /></div>
        <div class="carrier-row__name">
          <p>{{ carrier.name }}</p>
        </div>
        <div class="carrier-row__fav">
          <b-icon
            v-if="!carrier.fav"
            @click="favCarrier(carrier.name, index, true)"
            icon="star"
            aria-hidden="true"
          ></b-icon>
          <b-icon
            v-if="carrier.fav"
            @click="favCarrier(carrier.name, index, false)"
            class="star-fill"
            icon="star-fill"
            aria-hidden="true"
          ></b-icon>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { debounce } from "@/utils/Debounce";
import onReSize from "@/Extend/onResize";
import FormatCarrierName from "../../../Extend/FormatCarrierName";

export default {
  name: "FavoriteCarriers",
  mixins: [onReSize, FormatCarrierName],
  components: {},
  data() {
    return {
      carriersImages: [],
      favoriteCarriers: [],
      debouncer: null,
    };
  },
  async created() {
    this.debouncer = debounce(() => this.favCarrierInDb(), 1500);
    this.favoriteCarriers = JSON.parse(localStorage.getItem("user-settings")).favorite_carriers;
    const carriersImages = await this.$store.dispatch("carriers/getCarriersImages");
    this.carriersImages = carriersImages
      .filter(this.filterByLtl)
      .map(this.setCarrierName)
      .map(this.setFavStatus);
  },
  methods: {
    filterByLtl(carrier) {
      return carrier.name.includes("LTL");
    },
    setCarrierName(carrier) {
      return {
        ...carrier,
        name: this.formatterCarrierName(carrier.name),
      };
    },
    setFavStatus(carrier) {
      return {
        ...carrier,
        fav: this.favoriteCarriers.includes(carrier.name),
      };
    },
    changeView(pathName) {
      if (this.$route.name !== pathName) {
        this.$router.push({
          name: pathName,
        });
      }
    },
    async favCarrier(carrier, index, favValue) {
      if (favValue) {
        this.favoriteCarriers.push(carrier);
      } else {
        this.favoriteCarriers = this.favoriteCarriers.filter((item) => item !== carrier);
      }
      this.carriersImages[index].fav = favValue;
      await this.debouncer();
    },
    async favCarrierInDb() {
      await this.$store.dispatch("account/updateAccountSettings", {
        settings: {
          favorite_carriers: this.favoriteCarriers,
        },
      });
      const userSettings = JSON.parse(localStorage.getItem("user-settings"));
      userSettings.favorite_carriers = this.favoriteCarriers;
      localStorage.setItem("user-settings", JSON.stringify(userSettings));
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/components/Modal.scss";

.favorite-carriers {
  display: flex;
  flex-direction: column;
  font-family: $font-family-portal;
  padding: 2%;
  @media (max-width: 800px) {
    width: 97%;
    margin: auto;
    padding: 2% 0;
  }
  &__header {
    display: flex;
    padding: 1%;
    background-color: #efefef;
    border: 2px solid $color-border-container;
    border-radius: 15px;
    font-weight: bold;
    font-size: 0.875rem;
    &-name {
      width: 25%;
      border-right: 2px solid $color-border-container;
    }
    &-description {
      width: 65%;
      border-right: 2px solid $color-border-container;
    }
    &-edit {
      width: 10%;
    }
  }

  &__back {
    display: flex;
    align-items: center;
    margin-bottom: 2%;
    @include font-small-standart-text;
    font-weight: bold;
    width: 160px;
    cursor: pointer;
    i {
      font-size: 16px;
      margin-right: 5px;
    }
  }
}

.carrier-row {
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: 30% 50% 20%;
  padding: 10px 0px;
  border-bottom: 1px solid $color-border-container;
  &__name {
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 600px) {
      p {
        padding: 8px;
        margin: 0px;
      }
    }
  }
  &__image {
    margin-left: 15%;
    width: 150px;
    height: 70px;
    @media (max-width: 600px) {
      width: 100px;
      margin-left: 1%;
    }
    & img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  &__fav {
    display: flex;
    align-items: center;
    justify-content: center;
    & svg {
      cursor: pointer;
      font-size: 45px;
    }
    & .star-fill {
      color: #f3d915;
    }
  }
}
</style>
